<template>
  <div class="login-main">
    <reset-password
      v-if="resetPasswordVisible"
      @closed="resetPasswordVisible=false">
    </reset-password>

    <div
      v-else
      class="login-container">
      <div class="login">

        <div class="title">
          <span>
            Welcome to Bunkerchain
          </span>
        </div>
        <div class="login-form" >
          <el-form
            ref="form"
            :model="formData"
            :rules="rules">
            <el-form-item
              required
              label="Username"
              prop="username">
              <el-input v-model="formData.username"></el-input>
            </el-form-item>
            <el-form-item
              label="Password"
              required
              prop="password">
              <el-input
                type="password"
                show-password
                v-model="formData.password"
                @keyup.enter.native="login">
              </el-input>
            </el-form-item>

            <!--            <el-form-item-->
            <!--              required-->
            <!--              prop="verifyCode">-->
            <!--              <div style="display: flex">-->
            <!--                <el-input-->
            <!--                  style="margin-right: 20px"-->
            <!--                  :placeholder="$t('verificationCode')"-->
            <!--                  prefix-icon="el-icon-news"-->
            <!--                  v-model="formData.verifyCode"-->
            <!--                  @keyup.enter.native="login">-->
            <!--                </el-input>-->
            <!--                <el-button-->
            <!--                  style="margin-left: 10px"-->
            <!--                  round-->
            <!--                  :loading="emailCodeLoading"-->
            <!--                  :disabled="emailCodeCountDown>0"-->
            <!--                  @click="getLoginCode">-->
            <!--                  {{ $t('getCode') }}-->
            <!--                  <span v-show="emailCodeCountDown>0">-->
            <!--                    {{ `(${emailCodeCountDown})` }}-->
            <!--                  </span>-->
            <!--                </el-button>-->
            <!--              </div>-->
            <!--            </el-form-item>-->

            <el-form-item>
              <div class="remember-account">
                <el-checkbox
                  style="margin-left: 5px"
                  v-model="rememberAccount">
                  {{ $t('rememberAccount') }}
                </el-checkbox>
                <span
                  class="forget-password"
                  @click="resetPasswordVisible = true">
                  Forget your password?
                </span>
              </div>
            </el-form-item>
            <el-button
              style="width: 160px"
              type="primary"
              round
              :loading="loginLoading"
              @click="login">
              {{ $t('login') }}
            </el-button>
          </el-form>
        </div>
        <!-- <div class="language">
          <span
            class="cn"
            :class="{active:$i18n.locale==='cn'}"
            @click="handleSetLanguage('cn')">
            中文
          </span>
          <span
            class="en"
            :class="{active:$i18n.locale==='en'}"
            @click="handleSetLanguage('en')">
            English
          </span>
        </div> -->
      </div>
    </div>
    <div
      class="company-register"
      @click="toCompanyRegister">
      {{ $t('enterpriseRegister') }}
    </div>
    <!--    <a-->
    <!--      href="https://bunkerchain.io"-->
    <!--      target="_blank">-->
    <!--      https://bunkerchain.io-->
    <!--    </a>-->
  </div>
</template>

<script>
import baseMixin from '@/mixins/base-mixin'
import ResetPassword from './components/ResetPassword'
import { TERMINAL_BERTH } from '@/constants.js'
import { formatDataCascaderLocation } from '@/utils/index.js'
import { mapState } from "vuex";
export default {
  name: 'Login',
  mixins: [baseMixin],
  components: { ResetPassword },
  data () {
    return {
      TERMINAL_BERTH,
      loginLoading: false,
      emailCodeLoading: false,
      currentLoginType: 'email',
      language: localStorage.language || 'cn',
      formData: {
        username: localStorage.username || '',
        password: '',
        verifyCode: '',
        verifyCodeId: ''
      },
      rules: {
        username: [
          {
            required: true,
            message: this.$t('usernameCannotBeEmpty'),
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: this.$t('pswNoEmpty'),
            trigger: 'blur'
          }
        ],
        verifyCode: [
          {
            required: true,
            message: this.$t('verifyCodeNoEmpty'),
            trigger: 'blur'
          }
        ]
      },
      rememberAccount: true,
      loading: false,
      resetPasswordVisible: false,
      email: '',
      captchaUrl: ''
    }
  },
  computed: {
    ...mapState({
      isMobileScreen: "isMobileScreen",
    }),
  },
  created () {
    this.$store.commit('setToken', '')
    // this.getCaptchaImage()
  },
  methods: {
    formatDataCascaderLocation,
    getCaptchaImage () {
      /**
       * 获取验证码
       */
      this.$request.post({
        url: this.$apis.getCaptchaImage
      }).then(res => {
        this.formData.verifyCodeId = res.data.verifyCodeId
        this.captchaUrl = `data:image/jpeg;base64,${res.data.verifyCode}`
      })
    },

    handleSetLanguage (lang) {
      /**
       * 切换语言
       */
      this.$i18n.locale = lang
      localStorage.language = lang
    },

    setCompanyOptions (companyList) {
      /**
       * 设置切换企业和团队的下拉选项
       */
      const companyOptions = []
      companyList.forEach(company => {
        if (company.enable) {
          const teamOptions = [];
          (company.teams ?? []).forEach(team => {
            teamOptions.push({
              id: team.id,
              value: team.id,
              label: team.name
            })
          })
          companyOptions.push({
            id: company.id,
            value: company.id,
            label: company.companyName,
            children: teamOptions
          })
        }
      })
      this.$store.commit('setCompanyOptions', companyOptions)
    },

    login () {
      /**
       * 登录
       */
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loginLoading = true
          this.$request.post({
            url: this.$apis.login,
            data: {
              user: { ...this.formData },
              verifyCode: this.formData.verifyCode,
              verifyCodeId: this.formData.verifyCodeId,
              verifyType: 1
            }
          }).then(res => {
            if (res?.code === 1000) {
              const { data } = res
              const { sysUserExts = [] } = data
              this.$store.commit('setUser', data.user)
              this.$store.commit('setToken', data.token || 'no token')
              this.$store.commit('setRoles', data.roles)
              this.$store.commit('setPermissions', data.roles.filter(r => r.code).map(r => { return r.code.toUpperCase() }))
              this.$store.commit('setCurrentTeamId', data.teams?.find(Boolean)?.id)
              this.$store.dispatch('getLocationList')
              this.$store.dispatch('getProductList')
            //  this.$store.dispatch('getVesselList')
              const roles = data.roles ?? []
              if (roles.length && roles[0].code === this.$roleCode.systemAdmin) {
                this.$store.commit('setUserExt', sysUserExts[0])
                // 如果是超级管理员，直接进入系统
                this.getMenusAndFunctionsByUser(data.user.id, undefined)
              } else {
                const companyList = data.organizations || []
                // 其他人员,检测是否有已经启用的企业
                if (companyList.length) {
                  this.$store.commit('setCompanyList', companyList)
                  this.$store.commit('setCurrentCompany', companyList[0])
                  // 获取登录用户所在的第一个企业的权限
                  const isShip = roles.length === 1 && roles[0].code === this.$roleCode.ship
                  this.getMenusAndFunctionsByUser(data.user.id, companyList[0].id, 'login', isShip)
                  if (sysUserExts.length) {
                    this.$store.commit('setUserExt', sysUserExts.find(user => user.tenantId === companyList[0].id))
                  }
                  this.$store.dispatch('getCompanySettings', companyList[0].id)
                } else {
                  this.$message.error(this.$t('noActiveCompany'))
                }
              }
              // 用户选择记住账号则缓存账号
              localStorage.username = this.rememberAccount ? this.formData.username : undefined
            }
          }).catch(() => {
          }).finally(() => {
            this.loginLoading = false
          })
        }
      })
    },

    getLoginCode () {
      /**
       * 获取登陆验证码
       */
      if (this.formData.username) {
        this.emailCodeLoading = true
        this.getEmailCodeInternal(this.formData.username).then(verifyCodeId => {
          this.formData.verifyCodeId = verifyCodeId
        })
      }
    },

    toCompanyRegister () {
      /**
       * 跳转到企业注册页面
       */
      this.$router.push({ name: 'companyRegister' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "index";
</style>
